import { createSelector } from "reselect";
import { tHomepageData, tHeaderAffiliates, tHeaderStats, tContentText, tLink, tContent, tMapAffiliates, tMap, tContacts, tNews, tNewsData } from "../types";
import moment from "moment";

const getState = (state) => state.homepageReducer.homepage;
const getNews = (state) => state.homepageReducer.news;

const homepageSelector = createSelector([getState, getNews], (item, news) => {

    let results: tHomepageData;

    if (item) {

        //let headerStats: tHeaderStats;
        let headerAffiliates: tHeaderAffiliates[] = [];
        let headerStats: tHeaderStats[] = [];
        let content: tContent[] = [];
        let link: tLink;
        let map: tMap;
        let contacts: tContacts;
        let newsData: tNewsData;

        const { attributes } = item;

        if (attributes && attributes.Affiliates && attributes.Affiliates.length > 0) {
            attributes.Affiliates.forEach(affiliate => {

                if (affiliate.Title) {
                    headerAffiliates = [
                        ...headerAffiliates,
                        {
                            id: affiliate.id,
                            title: affiliate.Title,
                        }
                    ];
                }
            });
        }

        if (attributes && attributes.HeaderStats && attributes.HeaderStats.length > 0) {
            attributes.HeaderStats.forEach(stat => {
                headerStats = [
                    ...headerStats,
                    {
                        id: stat.id,
                        title: stat.Title,
                        caption: stat.Caption,
                    }
                ];
            });
        }

        if (attributes && attributes.Content && attributes.Content.length > 0) {
            attributes.Content.forEach(cont => {

                let contentText: tContentText[] = [];
                let link: tLink;

                if (cont && cont.Text && cont.Text.length > 0) {
                    cont.Text.forEach(text => {
                        contentText = [
                            ...contentText,
                            {
                                id: text.id,
                                title: text.Title,
                                description: text.Description,
                            }
                        ];
                    });
                }

                if (cont.Link && cont.Link.Title && cont.Link.Url) {
                    link = {
                        title: cont.Link.Title,
                        url: cont.Link.Url,
                    };
                }
                content = [
                    ...content,
                    {
                        id: cont.id,
                        title: cont.Title,
                        description: cont.Description,
                        text: contentText,
                        image: cont.Image && cont.Image.data && cont.Image.data.attributes && cont.Image.data.attributes.url && cont.Image.data.attributes.url,
                        link,
                    }
                ];

            });
        }

        if (attributes && attributes.Map) {

            let mapAffiliates: tMapAffiliates[] = [];
            if (attributes.Map.Affiliates && attributes.Map.Affiliates.length > 0) {
                attributes.Map.Affiliates.forEach(mapAff => {
                    mapAffiliates = [
                        ...mapAffiliates,
                        {
                            id: mapAff.id,
                            title: mapAff.Title,
                            description: mapAff.Description,
                            position: mapAff.Position && mapAff.Position.split(";"),
                            type: mapAff.Type,
                            typeTitle: mapAff.TypeTitle,
                        }
                    ];
                });
            }

            let link: tLink;

            if (attributes.Map.Link && attributes.Map.Link.Title && attributes.Map.Link.Url) {
                link = {
                    title: attributes.Map.Link.Title,
                    url: attributes.Map.Link.Url,
                };
            }

            map = {
                id: attributes.Map.id,
                title: attributes.Map.Title,
                description: attributes.Map.Description,
                affiliates: mapAffiliates,
                link,
            };
        }

        if (attributes && attributes.Contacts) {
            let link: tLink = null;
            if (attributes.Contacts.Link && attributes.Contacts.Link.Title && attributes.Contacts.Link.Url) {
                link = {
                    title: attributes.Contacts.Link.Title,
                    url: attributes.Contacts.Link.Url,
                };
            }

            contacts = {
                title: attributes.Contacts.Title,
                description: attributes.Contacts.Description,
                link,
            };
        }

        let newsList: any[] = [];
        if (news && news.length > 0) {
            news.forEach(newsItem => {
                newsList = [
                    ...newsList,
                    {
                        id: newsItem.id,
                        slug: newsItem.attributes.Slug,
                        title: newsItem.attributes.Title,
                        date: moment(newsItem.attributes.Date),
                        preview: newsItem.attributes.Preview,
                        thumb:
                            newsItem.attributes.Image328x220 &&
                            newsItem.attributes.Image328x220.data &&
                            newsItem.attributes.Image328x220.data.attributes &&
                            newsItem.attributes.Image328x220.data.attributes.url &&
                            newsItem.attributes.Image328x220.data.attributes.url,
                    }
                ];
            });
        }

        if (attributes && attributes.Contacts) {
            let link: tLink = null;
            if (attributes.Contacts.Link && attributes.Contacts.Link.Title && attributes.Contacts.Link.Url) {
                link = {
                    title: attributes.Contacts.Link.Title,
                    url: attributes.Contacts.Link.Url,
                };
            }

            contacts = {
                title: attributes.Contacts.Title,
                description: attributes.Contacts.Description,
                link,
            };
        }

        if (attributes) {
            newsData = {
                title: (attributes.News && attributes.News.Title) && attributes.News.Title || "News",
                linkCaption: (attributes.News && attributes.News.LinkCaption) && attributes.News.LinkCaption || "All Updates",
            };
        }

        results = {
            id: item.id,
            title: attributes && attributes.Title,
            description: attributes && attributes.Description,
            headerStats,
            headerAffiliates,
            content,
            map,
            contacts,
            newsList,
            newsData,
        }
    }

    return results;
});

export default homepageSelector;
