import React, { useEffect, useState } from 'react';

const MapCard = (props:any) => {

    const {
        id,
        children,
        type,
        title,
        position,
        openedMapCard,
        setOpenedMapCard,
    } = props;

    //const [ opened, setOpened ] = useState<boolean>(false);
    const [isMobile, setMobile] = useState(false);
    useEffect(() => {
        function resize() {
            if (window.innerWidth < 850) {
                setMobile(true);
            }
            else {
                setMobile(false);
            }
        }
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    })

    return (
        <div
            className={`map__card${openedMapCard === id && " _opened" || ""} _${type}`}
            style={{left: position[0] + "%", top: position[1] + "%"}}
        >
            <div
                className="map__card__title"
                onClick={() => {
                    let newId:number = 0;
                    if (id !== openedMapCard) {
                        newId = id;
                    }
                    setOpenedMapCard(newId);
                }}
                onMouseEnter={() => {
                    if (isMobile) return;
                    let newId:number = 0;
                    if (id !== openedMapCard) {
                        newId = id;
                    }
                    setOpenedMapCard(newId);
                }}
                onMouseLeave={() => {
                    if (isMobile) return;
                    setOpenedMapCard(null);;
                }}
            >
                {title}
            </div>
            <div className="map__card__info">
                {children}
            </div>
        </div>
    );
};

export default MapCard;
