import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { imgUrl } from "../../config/config";
import { getHomepage } from '../../store/ducks/homepage/actions';
import homepageSelector from '../../store/ducks/homepage/selectors/homepageSelector';
//import { StateStatus } from 'types/models';
import { Link } from 'react-router-dom';
//import { Helmet } from 'react-helmet';
//import fetch from 'isomorphic-fetch';
//import cn from 'classnames';
import { siteUrl } from '../../config/config';
import moment from 'moment';
import MapCard from './MapCard';
import { PageMeta } from 'components/PageMeta/PageMeta';

// import './Home.scss';
import './Main.scss';
import { LangContext } from "../../components/App/App";

type tMapTypes = {
    id: number;
    type: string;
    title: string;
    image: string;
};

type tActiveMapTypes = {
    type: string;
    title: string;
};

let mapTypes: tMapTypes[] = [
    {
        id: 1,
        type: "head-office",
        title: "Head Office",
        image: "office1.svg",
    },
    {
        id: 2,
        type: "office",
        title: "Office",
        image: "office2.svg",
    },
    {
        id: 3,
        type: "warehouse",
        title: "Warehouse",
        image: "office3.svg",
    },
    {
        id: 4,
        type: "port",
        title: "Port",
        image: "office4.svg",
    },
    {
        id: 5,
        type: "blending",
        title: "Blending",
        image: "office5.svg",
    },
    {
        id: 6,
        type: "supplier",
        title: "Supplier",
        image: "office6.svg",
    },
];

const Main = () => {
    const dispatch = useDispatch();
    const homepage = useSelector(homepageSelector);
    const status = useSelector(state => state.homepageReducer.status);
    const [ openedMapCard, setOpenedMapCard ] = useState(0);
    const [ activeMapTypes, setActiveMapTypes ] = useState<tActiveMapTypes[]>([]);
    const map = useRef(null);

    const lang = useContext(LangContext);

    useEffect(() => {
        if (lang && lang.lang && lang.lang.value) {
            dispatch(getHomepage({lang: lang.lang.value}));
        }
    }, [lang]);

    useEffect(() => {
        if (map && map.current) {
            setTimeout(() => {
                //map.current.scrollTo(0, 500)
            }, 1000);
        }
    }, [map]);

    useEffect(() => {
        if (homepage && homepage.map && homepage.map.affiliates && homepage.map.affiliates.length > 0) {
            let activeTypes: tActiveMapTypes[] = [];
            homepage.map.affiliates.forEach(affiliate => {
                if (activeTypes.indexOf(affiliate.type) === -1) {
                    activeTypes = [
                        ...activeTypes,
                        {
                            type: affiliate.type,
                            title: affiliate.typeTitle,
                        },
                    ];
                }
            });
            //console.log("activeTypes: ", activeTypes);
            if (activeTypes && activeTypes.length > 0) {
                activeTypes.forEach(activeType => {
                    mapTypes.forEach(mapType => {
                        if (activeType.type === mapType.type && activeType.title) {
                            mapType.title = activeType.title;
                        }
                    });
                });
            }
            setActiveMapTypes([...activeTypes]);
        }
    }, [homepage]);

    return (
        <>
            <PageMeta title={homepage && homepage.title} />
            {homepage && status === 'SUCCESS' && (
                <>
                    <section className="main">
                        <div className="container">
                            <div className="main-wrapper">
                                {homepage.title && (
                                    <h1
                                        className="main-title"
                                        dangerouslySetInnerHTML={{
                                            __html: homepage.title,
                                        }}
                                    />
                                )}
                                {homepage.description && (
                                    <div
                                        className="main-description"
                                        dangerouslySetInnerHTML={{
                                            __html: homepage.description,
                                        }}
                                    />
                                )}
                                {homepage.headerAffiliates &&
                                    homepage.headerAffiliates.length > 0 && (
                                        <div className="main-location">
                                            {homepage.headerAffiliates.map(
                                                item => (
                                                    <div
                                                        key={item.id}
                                                        className="location"
                                                    >
                                                        {item.title}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                {homepage.headerStats &&
                                    homepage.headerStats.length > 0 && (
                                        <div className="main-bottom">
                                            <div className="main-rezults">
                                                {homepage.headerStats.map(
                                                    item => (
                                                        <div
                                                            className="rezults-block"
                                                            key={item.id}
                                                        >
                                                            <div className="rezults-count">
                                                                {item.title}
                                                            </div>
                                                            <div className="rezults-text">
                                                                {item.caption}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </section>

                    {homepage.content && homepage.content.length > 0 && (
                        <section className="about">
                            <div className="container">
                                <div className="about-wrapper">
                                    {homepage.content.map(item => (
                                        <div className="about-block">
                                            <div className="about-img">
                                                <img
                                                    src={siteUrl + item.image}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="about-content">
                                                <div
                                                    className="about-title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.title,
                                                    }}
                                                />
                                                <div className="about-grid">
                                                    {!item.description &&
                                                        item.text &&
                                                        item.text.length > 0 &&
                                                        item.text.map(text => (
                                                            <div className="about-grid__item">
                                                                <div className="about-grid__item-title">
                                                                    {text.title}
                                                                </div>
                                                                <div className="about-grid__item-text">
                                                                    {
                                                                        text.description
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                                    {item.description && (
                                                        <div className="about-description">
                                                            We offer over 80
                                                            high-quality
                                                            fertilizers and
                                                            components, from
                                                            essential nutrients
                                                            like nitrogen,
                                                            phosphorus, and
                                                            potassium to
                                                            advanced
                                                            formulations
                                                            tailored to specific
                                                            needs.
                                                        </div>
                                                    )}
                                                    {item.link && (
                                                        <Link
                                                            to={item.link.url}
                                                            className="about-block__btn btn-see"
                                                        >
                                                            {item.link.title}
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    )}

                    {homepage.map && (
                        <section className="world">
                            <div className="container">
                                <div className="world-wrapper">
                                    {homepage.map.title && (
                                        <h2
                                            className="section-title"
                                            dangerouslySetInnerHTML={{
                                                __html: homepage.map.title,
                                            }}
                                        />
                                    )}
                                    {homepage.map.description && (
                                        <div
                                            className="section-description"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    homepage.map.description,
                                            }}
                                        />
                                    )}
                                    {homepage.map.link && (
                                        <Link
                                            to={homepage.map.link.url}
                                            className="btn-see"
                                        >
                                            {homepage.map.link.title}
                                        </Link>
                                    )}

                                    <div className="map-cover">
                                        <div className="map" ref={map}>
                                            {homepage.map.affiliates && homepage.map.affiliates.length > 0 && homepage.map.affiliates.map(item => (
                                                <MapCard
                                                    key={item.id}
                                                    id={item.id}
                                                    title={item.title}
                                                    position={item.position}
                                                    type={item.type}
                                                    setOpenedMapCard={setOpenedMapCard}
                                                    openedMapCard={openedMapCard}
                                                >
                                                    <div dangerouslySetInnerHTML={{__html: item.description}} />
                                                </MapCard>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="map-wrapper">
                                        <div className="map-list">
                                            {mapTypes && mapTypes.length > 0 && mapTypes.map(item => (
                                                <>
                                                    {activeMapTypes.map(e => e.type).indexOf(item.type) > -1 &&
                                                        <div className="map-list__item" key={item.id}>
                                                            <img src={imgUrl + item.image} alt=""/>
                                                            <span>{item.title}</span>
                                                        </div>
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {homepage.newsList && homepage.newsList.length > 0 && (
                        <section className="news">
                            <div className="container">
                                <div className="news-wrapper">
                                    <h2 className="section-title">
                                        <span>{homepage.newsData && homepage.newsData.title && homepage.newsData.title}</span>
                                        <Link to="/news">
                                            {homepage.newsData && homepage.newsData.linkCaption && homepage.newsData.linkCaption}{' '}
                                            <img
                                                src={imgUrl + 'news-arrow.svg'}
                                                alt=""
                                            />
                                        </Link>
                                    </h2>
                                    {homepage.newsList.map(item => (
                                        <div
                                            className="news-item"
                                            key={item.id}
                                        >
                                            <div className="news-item__date">
                                                {lang && lang.lang.locale === "zh-cn"
                                                    && moment(item.date).locale(lang.lang.locale).format("LL")
                                                    || moment(item.date).locale("ru").format("L")
                                                }
                                            </div>
                                            <Link
                                                to={'/post/' + item.slug}
                                                className="news-item__text"
                                            >
                                                {item.preview}
                                            </Link>
                                            {item.thumb &&
                                                <div className="news-item__img">
                                                    <img
                                                        src={siteUrl + item.thumb}
                                                        alt=""
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    )}

                    {homepage.contacts && homepage.contacts.link && (
                        <section className="bottom-sec">
                            <div className="container">
                                <div className="bottom-sec__wrapper">
                                    <h2
                                        className="section-title"
                                        dangerouslySetInnerHTML={{
                                            __html: homepage.contacts.title,
                                        }}
                                    />
                                    <div
                                        className="section-description"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                homepage.contacts.description,
                                        }}
                                    />
                                    {homepage.contacts.link &&
                                        homepage.contacts.link.title &&
                                        homepage.contacts.link.url && (
                                            <Link
                                                to={homepage.contacts.link.url}
                                                className="contact-us"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        homepage.contacts.link
                                                            .title,
                                                }}
                                            ></Link>
                                        )}
                                </div>
                            </div>
                        </section>
                    )}
                </>
            )}
        </>
    );
};

export default Main;
